import React from 'react'
import { Navbar } from '../Navbar'

import './style.scss'

export interface LayoutProps {
    children: React.ReactNode,
    style?: React.CSSProperties,
}

const Footer = () => (
    <footer className="footer pt-3 mt-2">
        <hr className="horizontal dark mb-2" />
        <div className="container">
            <div className=" row">
                <div className="col-12">
                    <div className="text-center">
                        <p className="my-4 text-sm">
                All rights reserved. Copyright © {(new Date().getFullYear())} CAKE Corp.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

const Header: React.FC = () => (
    <>
    </>

)

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {

    return (
        <div id="custom-layout">

            <Navbar />

            <Header />

            <article>
                {props.children}
            </article>

            <Footer />

        </div>
    )
}

export default Layout
