import React from 'react'
import { useAppSelector } from 'helpers/store'
import { actions } from  'domain/Interface/state'
import { DEFAULT_LOCALE } from 'locale'
import { BPYKHeader } from './BPYKHeader'
import bilibiliLogo from 'assets/images/bilibili.svg'
import youtubeLogo from 'assets/images/youtube.svg'
import useLanguages from 'hooks/useLanguages'

type BPYKProps = {
    data: HybeEduLink
}

const BridgeButton = ({image, to, className}: {image: string, to: string, className?: string}): JSX.Element => (
    <a href={to} className={`btn btn-outline-primary bridge-link ${className}`}>
        <img src={image} alt="to" className="img-fluid" />
    </a>
)

export const BPYK: React.FC<BPYKProps> = ({ data }: BPYKProps) => {

    const allowedLanguages: Array<string> = ['en', 'cn']
    useLanguages(allowedLanguages)
    const { lang } = useAppSelector(state => state.ui)

    const { locales, buttons } = data.extra
    const locale = locales[lang] ? locales[lang] : locales[DEFAULT_LOCALE]

    return (
        <>
            <BPYKHeader title={locale.title} />

            <section className="bpyk-bridge py-2 mt-2">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-6 mt-lg-0 mt-4">

                            <BridgeButton className="youtube" to={buttons[0].target} image={youtubeLogo} />

                        </div>

                        <div className="col-lg-6 mt-lg-0 mt-4">

                            <BridgeButton className="bilibili" to={buttons[1].target} image={bilibiliLogo} />

                        </div>

                    </div>
                </div>

            </section>
        </>
    )

}


export default BPYK
