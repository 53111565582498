const es = {

    common: {
        linkError: 'There was an error retrieving the contents for this QR Code.',
    },

    lktb: {
        title: 'Práctica de Shadowing',
        description: '¡Habla! con BTS o Bora. Haz clic en el botón de enlace para ir a YouTube. ',
        chapter: 'Capítulo %s',
    },

    bpyk: {
        description:'Choose the platform to watch the video.',
    },

}

export default es
