import { useAppSelector } from 'helpers/store'
import useLanguages from 'hooks/useLanguages'
import { locales, DEFAULT_LOCALE } from 'locale'

export const About: React.FC = () => {
    useLanguages(Object.keys(locales))
    const { lang } = useAppSelector(state => state.ui)
    const locale = locales[lang] || locales[DEFAULT_LOCALE]

    return (
        <>
            <section className="home py-2 mt-8">
                <div className="container">
                    <div className="row align-items-center">
                        <h2>Learn Korean</h2>
                        <p>Link redirection service.</p>
                        <p>
                            {locale.common.linkError}
                        </p>
                    </div>
                </div>
            </section>
        </>
    )

}

export default About
