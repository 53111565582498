import React, { useEffect, useState } from 'react'

type YoutubeEmbedProps = {
    embedId: string | null
    show: boolean
    onLeave: () => void
}

export const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ show, embedId, onLeave }: YoutubeEmbedProps) => {
    const body = document.querySelector('body')
    const width = body ? body.clientWidth : 853
    const height = width / 1.77

    const [showIframe, toggleIframe] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => toggleIframe(show), 150)
        return () => clearTimeout(timer)
    }, [ show, toggleIframe ])

    return (

        <div className={`video-responsive ${show ? 'show' : ''}`} onClick={onLeave}>
            {showIframe && (
                <iframe
                    onBlur={onLeave}
                    width={width}
                    height={height}
                    src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            )}
        </div>
    )
}

export default YoutubeEmbed
