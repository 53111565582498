const jp = {

    common: {
        linkError: 'There was an error retrieving the contents for this QR Code.',
    },

    lktb: {
        title: 'シャドーイング練習',
        description: 'BTSやボラと話してみましょう！YouTube動画に繋がる各ボタンをクリックしてください。',
        chapter: 'Chapter %s',
    },

    bpyk: {
        description:'Choose the platform to watch the video.',
    },

}

export default jp
