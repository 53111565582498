import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'helpers/store'
import { DEFAULT_LOCALE } from 'locale'
import { YoutubeEmbed } from 'components/YoutubeEmbed'
import { LKTBButton, BridgeButtonProps } from './LKTBButton'
import { LKTBHeader } from './LKTBHeader'
import { LktbLocale } from './types'
import useLanguages from 'hooks/useLanguages'

type LKTBProps = {
    chapter: string
    buttons: Array<BridgeButtonProps>
    locales: LktbLocale
}

const extractVideoId = (targetUrl: string) => {
    if (targetUrl.indexOf('?v=') > -1) {
        return targetUrl.substring(targetUrl.indexOf('?v=') + 3)
    } else if (targetUrl.indexOf('youtu.be') > -1) {
        return targetUrl.substring(targetUrl.indexOf('youtu.be/') + 9)
    }
    return targetUrl
}

export const LKTB: React.FC<LKTBProps> = ({ chapter, buttons, locales}: LKTBProps) => {

    const allowedLanguages: Array<string> = ['en', 'es', 'jp']
    useLanguages(allowedLanguages)

    const defaultState = [false, '']
    const { lang } = useAppSelector(state => state.ui)
    const [showYoutube, toggleYoutube] = useState(defaultState)
    const locale = locales[lang] || locales[DEFAULT_LOCALE]

    const target1 =  extractVideoId(buttons[0].target)
    const target2 =  extractVideoId(buttons[1].target)

    useEffect(() => {
        const body = document.querySelector('body')
        if (showYoutube[0]) {
            body && body.classList.add('has-video')
            return () => {
                body && body.classList.remove('has-video')
            }
        }
    }, [ showYoutube ])

    return (
        <>
            <LKTBHeader chapter={chapter} />

            <section className="py-2 mt-2">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <LKTBButton
                                label={locale.buttons[0]}
                                color={buttons[0].color}
                                target={buttons[0].target}
                                onClick={ () => toggleYoutube([true, target1]) }
                            />
                        </div>

                        <div className="col-lg-6 mt-lg-0 mt-4">

                            <LKTBButton
                                label={locale.buttons[1]}
                                color={buttons[1].color}
                                target={buttons[1].target}
                                onClick={ () => toggleYoutube([true, target2]) }
                            />

                        </div>

                    </div>
                </div>

                <YoutubeEmbed
                    show={!!showYoutube[0]}
                    embedId={'' + showYoutube[1]}
                    onLeave={() => toggleYoutube([false, ''])}
                />
            </section>
        </>
    )

}


export default LKTB
