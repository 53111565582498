import en from './en'
import es from './es'
import jp from './jp'
import cn from './cn'


export type Language = {
    name: string
}

export type Languages = {
    [key: string]: Language
}

export interface Locale {
    [key: string]: {
        common: {
            [key:string]: string
        }
        lktb:  {
            [key:string]: string
        }
        bpyk:  {
            [key:string]: string
        }
    }
}

export const DEFAULT_LOCALE = 'en'

export const languages: Languages = {
    en: {
        name:'English',
    },
    jp: {
        name: '日本語',
    },
    es: {
        name: 'Español',
    },
    cn: {
        name: '中文',
    },
}

export const locales: Locale = {
    en,
    es,
    jp,
    cn,
}

export default locales
