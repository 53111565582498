import React, { useEffect, useState }  from 'react'
import { useAppSelector, useAppDispatch } from 'helpers/store'
import { actions } from '../state'
import { languages } from 'locale'


export const Navbar: React.FC = () => {
    const [show, toggle] = useState(false)
    const { lang, shownLanguages = [] } = useAppSelector(state => state.ui)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (Object.keys(languages).indexOf(lang) === -1) {
            dispatch(actions.lang('en'))
        }
    }, [ languages, lang, dispatch ])

    const language = languages[lang] || languages['en']

    const update = (lang: string) => {
        dispatch(actions.lang(lang))
        toggle(false)
    }

    const uiLanguages = shownLanguages || languages

    if (!uiLanguages) return null 

    const LanguagesItems = uiLanguages.map((e:string, i: number) => (
        <li key={`languageItem-${i}`} className={`px-4 py-2 ${lang === e ? 'text-bold' : ''}`} onClick={() => update(e)}>
            {languages[e].name} - {e.toUpperCase()}
        </li>
    ))


    return (
        <nav className="navbar position-absolute top-0 z-index-3 w-100 shadow-none navbar-transparent ">
            <div className="container d-flex flex-row-reverse px-0">
                <button
                    className={`navbar-toggler shadow-none ms-2 ${show ? '' : 'collapsed'}`}
                    type="button"
                    aria-controls="navigation"
                    aria-expanded={show ? 'true' : 'false'}
                    aria-label="Toggle navigation"
                    onClick={() => toggle(!show)}
                >
                    <span className="btn btn-rounded bg-white-80 text-dark">
                    🌐  {language.name}
                    </span>
                </button>

                <div className={`collapse navbar-collapse bg-white-80 w-100 py-2 ${show ? 'show' : ''}`} id="navigation">
                    <ul className="navbar-nav navbar-nav-hover">
                        {LanguagesItems}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
