import React, { useEffect } from 'react'
import { useAppSelector } from 'helpers/store'
import { locales, DEFAULT_LOCALE } from 'locale/'
import Waves from 'components/Waves'

type BPYKHeaderProps = {
    title: string 
}

const nl2br = (input: string): Array<JSX.Element> => (input || '').split('\n').map(
    (substr: string, i: number) => (
        <React.Fragment key={i}>
            {substr}<br />
        </React.Fragment>
    )
)

export const BPYKHeader: React.FC<BPYKHeaderProps> = ({ title }: BPYKHeaderProps) => {

    const { lang } = useAppSelector(state => state.ui)
    const locale = locales[lang].bpyk ? locales[lang].bpyk : locales[DEFAULT_LOCALE].bpyk
    const { description } = locale

    useEffect(() => {
        document.title += ` | ${title}`
    }, [ title ])

    return (
        <header className="bpyk-bridge bg-gradient-dark">
            <div className="page-header pt-4 ">
                <span className="mask bg-gradient-info opacity-8"></span>
                <div className="container my-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center mx-auto my-auto">
                            <h4 className="text-white">BLACKPINK IN YOUR KOREAN</h4>
                            <h2 className="text-hybe-yellow">{nl2br(title)}</h2>
                            <p className="fs-5 mb-4 text-white opacity-8">{nl2br(description)}</p>
                        </div>
                    </div>
                </div>

                <Waves />

            </div>
        </header>
    )
}

export default BPYKHeader
