import { useState, useEffect } from 'react'
import { getLinkContents } from 'helpers/api'


export const useQRLink = (link: string): { data: HybeEduLink|null , error?: string|null }  => {
    const [data, setData] = useState<HybeEduLink | null>(null)
    const [error, setError] = useState<string | null>()

    useEffect(() => {
        (async() => {
            if (!link) return
            try {
                const contents = await getLinkContents(link) 
                setData(contents)
            } catch(err: unknown) {
                if (err instanceof Error) setError(err.message)
            }
        })()
    }, [ setData, setError, link ])

    return { data, error } 
}

export default useQRLink
