import { Bridge } from 'domain/Bridge'
import { About } from 'domain/About'
import { Layout } from 'domain/Interface'
import { QRCode } from 'components/QRCode'


export const routes = [
    {
        path:'/',
        exact:true,
        children: <Layout><About /></Layout>,
    },
    {
        path:'/:key/qr',
        children: <QRCode />,
    },
    {
        path:'/:key',
        children: <Layout><Bridge /></Layout>,
    },
]
