const en = {

    common: {
        linkError: 'There was an error retrieving the contents for this QR Code.',
    },

    lktb: {
        title: 'Shadowing Practice',
        description: 'Talk! with BTS or Bora. Click each button linking to the YouTube video.',
        chapter: 'Chapter %s',
    },

    bpyk: {
        description:'Choose the platform to watch the video.',
    },

}

export default en
