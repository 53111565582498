import { useEffect } from 'react'


type RedirectorProps = {
    to: string
    delay?: number
}

const messages = {
    ok: 'Please wait, you will be redirected shortly...',
    broken: 'This link is broken :-(',
    error: 'An error occured, please try again later.',
}

export const Redirector: React.FC<RedirectorProps> = ({to, delay}: RedirectorProps) => {

    const isValidUrl = undefined !== to && to.indexOf('://') > -1
    const message = isValidUrl
        ? <span>{messages.ok}</span>
        : <span>{messages.broken}</span>

    useEffect(() => {
        if (isValidUrl) {
            const timeout = setTimeout(
                () => {
                    document.location.href = to
                },
                delay || 2000
            )
            return () => clearTimeout(timeout)
        }
    }, [ isValidUrl, to, delay ])

    return (
        <section className="home py-2 mt-8">
            <div className="container">
                <div className="row align-items-center">
                    <h2>Learn Korean</h2>
                    <p>Link redirection service.</p>
                    <p>
                        {message}
                    </p>
                </div>
            </div>
        </section>
    )

}

export default Redirector
