import { useEffect } from 'react'
import { DEFAULT_LOCALE } from 'locale'
import { useAppDispatch, useAppSelector } from 'helpers/store'
import { actions } from  'domain/Interface/state'
import { arrayEquals } from 'helpers/arrays'

export const useLanguages = (langs: Array<string>) => {

    const dispatch = useAppDispatch()
    const { lang, shownLanguages = [] } = useAppSelector(state => state.ui)
    
    useEffect(() => {

        if (!langs || !shownLanguages || !arrayEquals(langs, shownLanguages)) {
            dispatch(actions.showLanguages(langs))
        }

        if (shownLanguages && shownLanguages.indexOf(lang) === -1){
            dispatch(actions.lang(DEFAULT_LOCALE))
        }

    }, [dispatch, lang, shownLanguages])
}

export default useLanguages
