import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { languages, DEFAULT_LOCALE } from 'locale'

type InterfaceState = {
    loading: boolean
    error?: string|null
    lang: string
    shownLanguages: Array<string>
}

const initialState: InterfaceState = {
    loading:false,
    error:null,
    lang: DEFAULT_LOCALE,
    shownLanguages: Object.keys(languages),
}

const ui = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        lang(state: InterfaceState, action: PayloadAction<string, string>) {
            state.lang = action.payload
        },
        showLanguages(state: InterfaceState, action: PayloadAction<string[], string>) {
            state.shownLanguages = action.payload
        },
    }
})

const {
    lang,
    showLanguages,
} = ui.actions


export const actions = {
    lang,
    showLanguages,
}

export default ui.reducer
