import React from 'react'
import { useParams } from 'react-router-dom'
import { Loading } from 'components/Loading'
import { About } from 'domain/About'
import { LKTB } from './LKTB'
import { BPYK } from './BPYK'
import { Redirector } from './Redirector'

import useQRLink from 'hooks/useQRLink'

export const Bridge: React.FC = () => {
    const { key }: {key:string} = useParams()
    const { data, error } = useQRLink(key)
    let Component = <About />

    if (error && console) console.error(error)

    if (data === undefined) {

        return <Loading />

    } else if (data && data.extra && data.extra.type && data.extra.type === 'bpyk') {

        Component = ( <BPYK data={data} />)

    } else if (data && data.extra && data.extra.type && data.extra.type === 'lktb') {

        Component = (
            <LKTB
                chapter={data.extra.chapter}
                buttons={data.extra.buttons}
                locales={data.extra.locales}
            />
        )

    } else if (data && ('target' in data)) {

        const to = data ? data.target : ''
        Component = <Redirector to={to} />

    }

    return <>{Component}</>
}


export default Bridge
