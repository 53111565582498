import React from 'react'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Spinner } from 'reactstrap'

import { routes } from 'helpers/routes'
import store from 'helpers/store'

import 'theme/custom.scss'

const  persistor = persistStore(store)

const routeMapping = (value: ReactRoute) => ( <Route key={value.path} {...value} />)
const mainRoutes = routes.map(routeMapping)

export const App: React.FC = () => (
    <Provider store={store}>
        <PersistGate loading={<Spinner />} persistor={persistor}>
            <Router>
                <div id="hybeedu-link">
                    <Switch>
                        {mainRoutes}
                    </Switch>
                </div>
            </Router>
        </PersistGate>
    </Provider>
)

export default App
