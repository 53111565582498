import { Spinner } from 'reactstrap'


export const Loading: React.FC  = () => (
    <section className="loading">
        <div>
            <Spinner color="secondary">{' '}</Spinner>
        </div>
    </section>
)

export default Loading
