import { Button } from 'reactstrap'

export type BridgeButtonProps = {
    label?: string
    color: string
    target: string
    onClick?: () => void
}


export const LKTBButton: React.FC<BridgeButtonProps> = ({label, color, target, onClick}: BridgeButtonProps) => {
    const defaultAction = () => {
        window.location.href = target
    }

    return (
        <Button className={`btn py-lg-4 align-items-center w-100 h-100 shadow-lg ${color}`} onClick={onClick ? onClick : defaultAction}>
            <h4 className="text-center text-white mb-0">
                {label}
            </h4>
        </Button>
    )
}


export default LKTBButton
