import { useParams } from 'react-router-dom'
import QRCodeSVG  from 'qrcode.react'


export const QRCode: React.FC = () => {
    const { key }: {key:string} = useParams()

    const downloadQRCode = () => {
        const canvas = document.getElementById('qrcode') as HTMLCanvasElement
        console.log({canvas})
        const pngUrl = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
        const downloadLink = document.createElement('a')
        downloadLink.href = pngUrl
        downloadLink.download = `${key}.png`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    return (
        <div style={{ display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <QRCodeSVG
                id={'qrcode'}
                size={256}
                includeMargin={true}
                value={document.location.href.replace('/qr','')}
            />
            <button type="button" onClick={downloadQRCode}>Download image</button>
        </div>
    )
}
