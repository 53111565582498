import { DynamoDB } from 'aws-sdk'

const API_URL = process.env.REACT_APP_API_URL

export const getLinkContents = async (linkId: string): Promise<HybeEduLink|null> => {

    if (!linkId) throw new Error('No link identifier')
    const response = await fetch(`${API_URL}/${linkId}`)
    const parsed = await response.json()
    const result = parsed.Items

    //console.log({response, parsed, result})

    if (parsed.Count && parsed.Count > 0) {
        const output = DynamoDB.Converter.unmarshall( result[0] ) as unknown as HybeEduLink
        return output
    }

    return null
}
