import React, { useEffect } from 'react'
import { useAppSelector } from 'helpers/store'
import { locales, DEFAULT_LOCALE } from 'locale/'
import Waves from 'components/Waves'

type LKTBHeaderProps = {
    chapter: string
}

export const LKTBHeader: React.FC<LKTBHeaderProps> = ({ chapter }: LKTBHeaderProps) => {

    const { lang } = useAppSelector(state => state.ui)
    const locale = locales[lang].lktb ? locales[lang].lktb : locales[DEFAULT_LOCALE].lktb

    useEffect(() => {
        document.title += ` | ${locale.title}`
    }, [ locale ])

    const mapDescription = (e: string, i: number) => (
        <span key={i}>
            {e} <br />
        </span>
    )

    return (
        <header className="lktb-bridge bg-gradient-dark">
            <div className="page-header pt-4 ">
                <span className="mask bg-gradient-info opacity-8"></span>
                <div className="container my-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center mx-auto my-auto">
                            <h1 className="text-white">{locale.title}</h1>
                            <h2 className="text-hybe-yellow">{locale.chapter.replace('%s', chapter)}</h2>
                            <p className="fs-5 mb-4 text-white opacity-8">{locale.description.split('\n').map(mapDescription)}</p>
                        </div>
                    </div>
                </div>
                
                <Waves />

            </div>
        </header>
    )
}

export default LKTBHeader
